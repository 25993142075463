//
// user-variables.scss
// Use this to override Bootstrap and Around variables
//

// Example of a variable override to change Around background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$primary: #a62333;
$secondary: #dbdad6;
$dark: #474746;
$light: #f6f9fc;
